<script setup>
import MainBanner from "~/components/molecules/MainBanner.vue";

const props = defineProps({
  data: Object,
})

const campaign_banner = props.data.banner
</script>

<template>
  <MainBanner v-if="campaign_banner && !props.city"
              :url="campaign_banner.url"
              :mobile-src="campaign_banner.mobile_image"
              :src="campaign_banner.desktop_image"/>

</template>

<style scoped lang="scss">

</style>
